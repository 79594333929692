import { __export } from './chunk-QXAXOUZS.mjs';
import { ZERO, ONE, CurrencyAmount, Percent, ONE_HUNDRED_PERCENT } from '@plexswap/sdk-core';
import invariant from 'tiny-invariant';
import { ChainId } from '@plexswap/chains';
import { bscTokens, bscTestnetTokens } from '@plexswap/tokens';

// Aegis/index.ts
var Aegis_exports = {};
__export(Aegis_exports, {
  STABLE_POOL_MAP: () => STABLE_POOL_MAP,
  STABLE_SUPPORTED_CHAIN_IDS: () => STABLE_SUPPORTED_CHAIN_IDS,
  getD: () => getD,
  getLPOutput: () => getLPOutput,
  getLPOutputWithoutFee: () => getLPOutputWithoutFee,
  getQuoteExactIn: () => getQuoteExactIn,
  getQuoteExactOut: () => getQuoteExactOut,
  getStableSwapPools: () => getStableSwapPools,
  getSwapInput: () => getSwapInput,
  getSwapInputWithtouFee: () => getSwapInputWithtouFee,
  getSwapOutput: () => getSwapOutput,
  getSwapOutputWithoutFee: () => getSwapOutputWithoutFee,
  isStableSwapSupported: () => isStableSwapSupported
});
function getD({ amplifier, balances }) {
  const numOfCoins = balances.length;
  invariant(numOfCoins > 1, "To get constant D, pool should have at least two coins.");
  const sum = balances.reduce((s, cur) => s + BigInt(cur), ZERO);
  if (sum === ZERO) {
    return ZERO;
  }
  const n = BigInt(numOfCoins);
  const precision = ONE;
  const ann = BigInt(amplifier) * n;
  let dPrev = ZERO;
  let d = sum;
  for (let i = 0; i < 255; i += 1) {
    let dp = d;
    for (const b of balances) {
      dp = dp * d / (BigInt(b) * n + 1n);
    }
    dPrev = d;
    d = (ann * sum + dp * n) * d / ((ann - ONE) * d + (n + ONE) * dp);
    if (d > dPrev && d - dPrev <= precision) {
      break;
    }
    if (d <= dPrev && dPrev - d <= precision) {
      break;
    }
  }
  return d;
}
function getY({ amplifier, balances, i, j, x }) {
  const numOfCoins = balances.length;
  invariant(numOfCoins > 1, "To get y, pool should have at least two coins.");
  invariant(i !== j && i >= 0 && j >= 0 && i < numOfCoins && j < numOfCoins, `Invalid i: ${i} and j: ${j}`);
  const n = BigInt(numOfCoins);
  const d = getD({ amplifier, balances });
  let sum = ZERO;
  let c = d;
  const ann = BigInt(amplifier) * n;
  for (const [index, b2] of balances.entries()) {
    if (index === j) {
      continue;
    }
    let balanceAfterDeposit = BigInt(b2);
    if (index === i) {
      balanceAfterDeposit += BigInt(x);
    }
    invariant(balanceAfterDeposit > ZERO, "Insufficient liquidity");
    sum += balanceAfterDeposit;
    c = c * d / (balanceAfterDeposit * n);
  }
  c = c * d / (ann * n);
  const b = sum + d / ann;
  const precision = ONE;
  let yPrev = ZERO;
  let y = d;
  for (let k = 0; k < 255; k += 1) {
    yPrev = y;
    y = (y * y + c) / (2n * y + b - d);
    if (y > yPrev && y - yPrev <= precision) {
      break;
    }
    if (y <= yPrev && yPrev - y <= precision) {
      break;
    }
  }
  return y;
}
var PRECISION = 10n ** 18n;
var getRawAmount = (amount) => {
  return amount.quotient * PRECISION / 10n ** BigInt(amount.currency.decimals);
};
var parseAmount = (currency, rawAmount) => {
  return CurrencyAmount.fromRawAmount(currency, rawAmount * 10n ** BigInt(currency.decimals) / PRECISION);
};

// Aegis/getLPOutput.ts
function getLPOutput({
  amplifier,
  balances,
  totalSupply,
  amounts,
  fee
}) {
  const lpToken = totalSupply.currency;
  const lpTotalSupply = totalSupply.quotient;
  if (lpTotalSupply === ZERO || !balances.length || balances.every((b) => b.quotient === ZERO)) {
    const d = getD({ amplifier, balances: amounts.map(getRawAmount) });
    return CurrencyAmount.fromRawAmount(lpToken, d);
  }
  const currentBalances = [];
  const newBalances = [];
  for (const [i, balance] of balances.entries()) {
    const amount = amounts[i] || CurrencyAmount.fromRawAmount(balance.currency, 0);
    invariant(
      amount.currency.wrapped.equals(balance.currency.wrapped),
      "User input currency should be the same as pool balance currency."
    );
    const balanceRaw = getRawAmount(balance);
    const amountRaw = getRawAmount(amount);
    currentBalances.push(balanceRaw);
    newBalances.push(balanceRaw + amountRaw);
  }
  const d0 = getD({ amplifier, balances: currentBalances });
  const d1 = getD({ amplifier, balances: newBalances });
  invariant(d1 >= d0, "D1 should be greater than or equal than d0.");
  const isFirstSupply = lpTotalSupply <= ZERO;
  if (isFirstSupply) {
    return CurrencyAmount.fromRawAmount(totalSupply.currency, d1);
  }
  const n = currentBalances.length;
  const eachTokenFee = fee.multiply(n).divide(4 * (n - 1));
  let d2 = d1;
  for (const [i, b] of currentBalances.entries()) {
    const idealBalance = d1 * b / d0;
    let diff = ZERO;
    if (idealBalance > newBalances[i]) {
      diff = idealBalance - newBalances[i];
    } else {
      diff = newBalances[i] - idealBalance;
    }
    const feeAmount = eachTokenFee.multiply(diff).quotient;
    newBalances[i] = newBalances[i] - feeAmount;
  }
  d2 = getD({ amplifier, balances: newBalances });
  const expectedMintLP = lpTotalSupply * (d2 - d0) / d0;
  return CurrencyAmount.fromRawAmount(totalSupply.currency, expectedMintLP);
}

// Aegis/getLPOutputWithoutFee.ts
function getLPOutputWithoutFee(params) {
  return getLPOutput({ ...params, fee: new Percent(0) });
}
function getSwapOutput({
  amplifier,
  balances: balanceAmounts,
  outputCurrency,
  amount,
  fee
}) {
  const validateAmountOut = (a) => invariant(!a.lessThan(ZERO), "Insufficient liquidity to perform the swap");
  let i = null;
  let j = null;
  const balances = [];
  for (const [index, b] of balanceAmounts.entries()) {
    balances.push(getRawAmount(b));
    if (b.currency.wrapped.equals(amount.currency.wrapped)) {
      i = index;
      continue;
    }
    if (b.currency.wrapped.equals(outputCurrency.wrapped)) {
      j = index;
      continue;
    }
  }
  invariant(
    i !== null && j !== null && i !== j,
    "Input currency or output currency does not match currencies of token balances."
  );
  if (amount.quotient < ZERO) {
    const x = ONE_HUNDRED_PERCENT.subtract(fee).invert().multiply(getRawAmount(amount)).quotient;
    const y2 = getY({ amplifier, balances, i, j, x });
    const dy2 = y2 - balances[j];
    const amountOut2 = parseAmount(outputCurrency, dy2);
    validateAmountOut(amountOut2);
    return amountOut2;
  }
  const y = getY({ amplifier, balances, i, j, x: getRawAmount(amount) });
  const dy = balances[j] - y;
  const feeAmount = fee.multiply(dy).quotient;
  const amountOut = parseAmount(outputCurrency, dy - feeAmount);
  validateAmountOut(amountOut);
  return amountOut;
}
function getSwapOutputWithoutFee(params) {
  return getSwapOutput({ ...params, fee: new Percent(0) });
}
function getSwapInput({ amount, ...rest }) {
  return getSwapOutput({
    ...rest,
    amount: CurrencyAmount.fromRawAmount(amount.currency, -amount.quotient)
  });
}
function getSwapInputWithtouFee(params) {
  return getSwapInput({ ...params, fee: new Percent(0) });
}
function createQuoteGetter(isExactIn) {
  const getSwapQuote = isExactIn ? getSwapOutput : getSwapInput;
  const applySwap = (balances, amount, quote) => balances.map((b) => {
    if (b.currency.equals(amount.currency)) {
      return isExactIn ? b.add(amount) : b.subtract(amount);
    }
    if (b.currency.equals(quote.currency)) {
      return isExactIn ? b.subtract(quote) : b.add(quote);
    }
    return b;
  });
  return function getQuote(params) {
    const { balances, amplifier, fee, amount } = params;
    const quote = getSwapQuote(params);
    return [
      quote,
      {
        balances: applySwap(balances, amount, quote),
        amplifier,
        fee
      }
    ];
  };
}
var getQuoteExactIn = createQuoteGetter(true);
var getQuoteExactOut = createQuoteGetter(false);
var pools = [
  {
    lpSymbol: "USDT-WBNB LP",
    lpAddress: "0x9c47AF6db545cE3B9BEc337FEBfD919f4Fd35743",
    // PlexStableSwapLP
    token: bscTokens.usdt,
    // coins[0]
    quoteToken: bscTokens.wbnb,
    // coins[1]
    stableSwapAddress: "0x1db5871AB93e5464Ae65D17353B5d5471086300B",
    // PlexStableSwapTwoPool
    infoStableSwapAddress: "0x16682C6103A598Fa64eC8087A439dFA37F02A71C",
    // PlexStableSwapTwoPoolInfo  
    stableLpFee: 4e-4,
    stableLpFeeRateOfTotalFee: 0.5
  },
  {
    lpSymbol: "USDT-BUSD LP",
    lpAddress: "0xB70370E2346B35Cb0914D216Cc4a43491CB180DD",
    token: bscTokens.usdt,
    quoteToken: bscTokens.busd,
    stableSwapAddress: "0x6fc75530F09b60b0e71CeEC8776d9d7192B31990",
    infoStableSwapAddress: "0x16682C6103A598Fa64eC8087A439dFA37F02A71C",
    stableLpFee: 4e-4,
    stableLpFeeRateOfTotalFee: 0.5
  }
];
var pools2 = [
  {
    lpSymbol: "USDT-WBNB LP",
    lpAddress: "0xC81Fb08f341C5a4b8894f0B3d3f1A69eBF7d3cf8",
    // PlexStableSwapLP
    token: bscTestnetTokens.usdt,
    // coins[0]
    quoteToken: bscTestnetTokens.wbnb,
    // coins[1]
    stableSwapAddress: "0x3F81828aE64143038119B6e094aFfEFdf9471a65",
    // PlexStableSwapTwoPool
    infoStableSwapAddress: "0x7956c89A966E90DE55A94bEa98dE28c75F4A4C36",
    // PlexStableSwapTwoPoolInfo
    stableLpFee: 4e-4,
    stableLpFeeRateOfTotalFee: 0.5
  },
  {
    lpSymbol: "USDT-BUSD LP",
    lpAddress: "0xD66E7914FD99F4F7Cca906C0ac7dbaC010D3b804",
    // PlexStableSwapLP
    token: bscTestnetTokens.usdt,
    // coins[0]
    quoteToken: bscTestnetTokens.busd,
    // coins[1]
    stableSwapAddress: "0xB71752dBA19593c9478919FAe528ba60E8937a71",
    // PlexStableSwapTwoPool
    infoStableSwapAddress: "0x7956c89A966E90DE55A94bEa98dE28c75F4A4C36",
    // PlexStableSwapTwoPoolInfo
    stableLpFee: 4e-4,
    stableLpFeeRateOfTotalFee: 0.5
  }
];

// config/constants/stableSwap/pools.ts
var isStableSwapSupported = (chainId) => {
  if (!chainId) {
    return false;
  }
  return STABLE_SUPPORTED_CHAIN_IDS.includes(chainId);
};
var STABLE_SUPPORTED_CHAIN_IDS = [ChainId.BSC, ChainId.BSC_TESTNET];
var STABLE_POOL_MAP = {
  [ChainId.BSC]: pools,
  [ChainId.BSC_TESTNET]: pools2
};

// config/constants/stableSwap/index.ts
function getStableSwapPools(chainId) {
  if (!isStableSwapSupported(chainId)) {
    return [];
  }
  return STABLE_POOL_MAP[chainId] || [];
}

export { Aegis_exports, STABLE_POOL_MAP, STABLE_SUPPORTED_CHAIN_IDS, getD, getLPOutput, getLPOutputWithoutFee, getQuoteExactIn, getQuoteExactOut, getStableSwapPools, getSwapInput, getSwapInputWithtouFee, getSwapOutput, getSwapOutputWithoutFee, isStableSwapSupported };
