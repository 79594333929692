import { Token, ERC20Token, WBNB, WPLEX, WNATIVE, Native } from '@plexswap/sdk-core';
import { ChainId } from '@plexswap/chains';

// src/warningTokens.ts
var bscWarningTokens = {
  pokemoney: new Token(ChainId.BSC, "0x32ff5b4C3B1744F0344D96fA2f87799Ed2805749", 18, "PMY", "Pokemoney Coin", ""),
  free: new Token(ChainId.BSC, "0x880BCe9321c79cAc1D290De6d31DDE722C606165", 8, "FREE", "Freedom Protocol Token", ""),
  safemoon: new Token(
    ChainId.BSC,
    "0x8076C74C5e3F5852037F31Ff0093Eeb8c8ADd8D3",
    9,
    "SAFEMOON",
    "Safemoon Token",
    "https://safemoon.net/"
  )
};

// src/swapWarningTokens.ts
var { safemoon } = bscWarningTokens;
var SwapWarningTokens = {
  safemoon
};
var WAYA_BSC = new ERC20Token(
  ChainId.BSC,
  "0x32d9F70F6eF86718A51021ad269522Abf4CFFE49",
  18,
  "WAYA",
  "PlexSwap Farm Token",
  "https://swap.plexfinance.us/"
);
var WAYA_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  "0xA2af2640A694f91632e60befc7Fc30C1b787D505",
  18,
  "WAYA",
  "PlexSwap Farm Token",
  "https://swap.plexfinance.us/"
);
var WAYA_PLEXCHAIN = new ERC20Token(
  ChainId.PLEXCHAIN,
  "0x7589CF3115E060673FD0109fA6891464E13947A1",
  18,
  "WAYA",
  "PlexSwap Farm Token",
  "https://swap.plexfinance.us/"
);
var PLEXF_BSC = new ERC20Token(
  ChainId.BSC,
  "0xBB472510B1896C6992D658a7Ab69F7dF32a37b3c",
  9,
  "PLEX-F",
  "Symplexia Finance Token",
  "https://swap.plexfinance.us/"
);
var PLEXF_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  "0xb7a3DFd16663dbA80c8f93338d1c59bA21680921",
  9,
  "PLEX-F",
  "Symplexia Finance Token",
  "https://swap.plexfinance.us/"
);
var PLEXF_PLEXCHAIN = new ERC20Token(
  ChainId.PLEXCHAIN,
  "0xD0B07197d86434635fe30D69604751F3651c19a6",
  9,
  "PLEX-F",
  "Symplexia Finance Token",
  "https://swap.plexfinance.us/"
);
var USDP_PLEXCHAIN = new ERC20Token(
  ChainId.PLEXCHAIN,
  "0x69D1D347088e8916aC107b6679927C4EC5faB4a0",
  18,
  "USDP",
  "Symplexia USD Coin",
  "https://swap.plexfinance.us/"
);
var USDC_BSC = new ERC20Token(
  ChainId.BSC,
  "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
  18,
  "USDC",
  "Binance-Peg USD Coin",
  "https://www.centre.io/usdc"
);
var USDC_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  "0x64544969ed7EBf5f083679233325356EbE738930",
  18,
  "USDC",
  "Binance-Peg USD Coin",
  "https://www.centre.io/usdc"
);
var USDT_BSC = new ERC20Token(
  ChainId.BSC,
  "0x55d398326f99059fF775485246999027B3197955",
  18,
  "USDT",
  "Tether USD",
  "https://tether.to/"
);
var BUSD_BSC = new ERC20Token(
  ChainId.BSC,
  "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
  18,
  "BUSD",
  "Binance USD",
  "https://www.paxos.com/busd/"
);
var BUSD_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  "0x8516Fc284AEEaa0374E66037BD2309349FF728eA",
  18,
  "BUSD",
  "Binance USD",
  "https://www.paxos.com/busd/"
);
var PLEXF = {
  [ChainId.BSC]: PLEXF_BSC,
  [ChainId.BSC_TESTNET]: PLEXF_TESTNET,
  [ChainId.PLEXCHAIN]: PLEXF_PLEXCHAIN
};
var WAYA = {
  [ChainId.BSC]: WAYA_BSC,
  [ChainId.BSC_TESTNET]: WAYA_TESTNET,
  [ChainId.PLEXCHAIN]: WAYA_PLEXCHAIN
};
var BUSD = {
  [ChainId.BSC]: BUSD_BSC,
  [ChainId.BSC_TESTNET]: BUSD_TESTNET
};
var USDC = {
  [ChainId.BSC]: USDC_BSC,
  [ChainId.BSC_TESTNET]: USDC_TESTNET
};
var USDT = {
  [ChainId.BSC]: USDT_BSC
};
var USDP = {
  [ChainId.PLEXCHAIN]: USDP_PLEXCHAIN
};
var STABLE_COIN = {
  [ChainId.BSC]: USDT[ChainId.BSC],
  [ChainId.BSC_TESTNET]: BUSD[ChainId.BSC_TESTNET],
  [ChainId.PLEXCHAIN]: USDP[ChainId.PLEXCHAIN]
};

// ../utils/enumValues.ts
function* enumValues(enumObj) {
  let isStringEnum = true;
  for (const property in enumObj) {
    if (typeof enumObj[property] === "number") {
      isStringEnum = false;
      break;
    }
  }
  for (const property in enumObj) {
    if (isStringEnum || typeof enumObj[property] === "number") {
      yield enumObj[property];
    }
  }
}

// src/helpers.ts
var createEmptyList = () => {
  const list = {};
  for (const chainId of enumValues(ChainId)) {
    list[chainId] = {};
  }
  return list;
};
var EMPTY_LIST = createEmptyList();
function serializeTokens(unserializedTokens) {
  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: unserializedTokens[key].serialize };
  }, {});
  return serializedTokens;
}
function unwrappedToken(token) {
  if (token && token.equals(WNATIVE[token.chainId]))
    return Native.onChain(token.chainId);
  return token;
}
var bscTokens = {
  // bnb here points to the wbnb contract. Wherever the currency BNB is required, conditional checks for the symbol 'BNB' can be used
  bnb: new ERC20Token(
    ChainId.BSC,
    "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    18,
    "BNB",
    "BNB",
    "https://www.binance.com/"
  ),
  wbnb: WBNB[ChainId.BSC],
  waya: WAYA_BSC,
  plexf: PLEXF_BSC,
  busd: BUSD_BSC,
  usdc: USDC_BSC,
  usdt: USDT_BSC,
  dai: new ERC20Token(
    ChainId.BSC,
    "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
    18,
    "DAI",
    "Dai Stablecoin",
    "https://www.makerdao.com/"
  ),
  btcb: new ERC20Token(
    ChainId.BSC,
    "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
    18,
    "BTCB",
    "Binance BTC",
    "https://bitcoin.org/"
  ),
  ust: new ERC20Token(
    ChainId.BSC,
    "0x23396cF899Ca06c4472205fC903bDB4de249D6fC",
    18,
    "UST",
    "Wrapped UST Token",
    "https://mirror.finance/"
  ),
  eth: new ERC20Token(
    ChainId.BSC,
    "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
    18,
    "ETH",
    "Binance-Peg Ethereum Token",
    "https://ethereum.org/en/"
  ),
  ctk: new ERC20Token(
    ChainId.BSC,
    "0xA8c2B8eec3d368C0253ad3dae65a5F2BBB89c929",
    6,
    "CTK",
    "Certik Token",
    "https://www.certik.foundation/"
  ),
  ada: new ERC20Token(
    ChainId.BSC,
    "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47",
    18,
    "ADA",
    "Binance-Peg Cardano Token",
    "https://www.cardano.org/"
  ),
  doge: new ERC20Token(
    ChainId.BSC,
    "0xbA2aE424d960c26247Dd6c32edC70B295c744C43",
    8,
    "DOGE",
    "Binance-Peg Dogecoin",
    "https://dogecoin.com/"
  ),
  ankr: new ERC20Token(
    ChainId.BSC,
    "0xf307910A4c7bbc79691fD374889b36d8531B08e3",
    18,
    "ANKR",
    "Ankr",
    "https://www.ankr.com/"
  )
};
var bscTestnetTokens = {
  // bnb here points to the wbnb contract. Wherever the currency BNB is required, conditional checks for the symbol 'BNB' can be used
  bnb: new ERC20Token(
    ChainId.BSC_TESTNET,
    "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
    18,
    "BNB",
    "BNB",
    "https://www.binance.com/"
  ),
  wbtc: new ERC20Token(
    ChainId.BSC_TESTNET,
    "0xfC8bFbe9644e1BC836b8821660593e7de711e564",
    8,
    "WBTC",
    "Wrapped BTC"
  ),
  usdt: new ERC20Token(
    ChainId.BSC_TESTNET,
    "0x337610d27c682E347C9cD60BD4b3b107C9d34dDd",
    18,
    "USDT",
    "Tether USD"
  ),
  mockB: new ERC20Token(ChainId.BSC_TESTNET, "0x828E3FC56dD48E072e3B6F3C4FD4DDB4733c2C5e", 18, "MOCK B", "MOCK B"),
  mockA: new ERC20Token(ChainId.BSC_TESTNET, "0xc1eD9955C11585F47d0d6BfBC29034349A746a81", 18, "MOCK A", "MOCK A"),
  mockUSDT: new ERC20Token(ChainId.BSC_TESTNET, "0x0fB5D7c73FA349A90392f873a4FA1eCf6a3d0a96", 18, "USDT", "MOCK Token"),
  wbnb: WBNB[ChainId.BSC_TESTNET],
  waya: WAYA_TESTNET,
  busd: BUSD_TESTNET,
  plexf: PLEXF_TESTNET,
  usdc: USDC_TESTNET
};
var plexchainTokens = {
  // PLEX native here points to the wplex contract. Wherever the currency PLEX is required, conditional checks for the symbol 'BNB' can be used
  plex: new ERC20Token(
    ChainId.PLEXCHAIN,
    "0x50245424Afc53E67Ca1AAD2C90401568C0eFf53A",
    18,
    "PLEX",
    "PLEX",
    "https://swap.plexfinance.us"
  ),
  wplex: WPLEX[ChainId.PLEXCHAIN],
  waya: WAYA_PLEXCHAIN,
  plexf: PLEXF_PLEXCHAIN,
  usdp: USDP_PLEXCHAIN
};

export { BUSD, BUSD_BSC, BUSD_TESTNET, EMPTY_LIST, PLEXF, PLEXF_BSC, PLEXF_PLEXCHAIN, PLEXF_TESTNET, STABLE_COIN, SwapWarningTokens, USDC, USDC_BSC, USDC_TESTNET, USDP, USDP_PLEXCHAIN, USDT, USDT_BSC, WAYA, WAYA_BSC, WAYA_PLEXCHAIN, WAYA_TESTNET, bscTestnetTokens, bscTokens, plexchainTokens, serializeTokens, unwrappedToken };
