import { EXTENDED_TICK_LENS_ADDRESSES } from './chunk-3JUB3JDQ.mjs';
import { getPairCombinations, getExtendedPoolsWithoutTicksOnChain, serializePool, serializeCurrency, serializeCurrencyAmount, parsePool, parseCurrency, parseCurrencyAmount, isStablePool, createPoolQuoteGetter, getPriceImpact, formatFraction, getTokenPrice, getPoolAddress, isCorePool, isExtendedPool, buildBaseRoute } from './chunk-TFI73TPS.mjs';
import { BASE_SWAP_COST_CORE, COST_PER_INIT_TICK, BASE_SWAP_COST_EXTENDED, COST_PER_HOP_EXTENDED, BASE_SWAP_COST_STABLE_SWAP } from './chunk-7CJLLFMP.mjs';
import { __export } from './chunk-3YPNWKHB.mjs';
import { multicallByGasLimit } from '@plexswap/multicall';
import { Tick, FeeAmount, TICK_SPACINGS, TickList } from '@plexswap/sdk-extended';
import { encodeFunctionData, decodeFunctionResult } from 'viem';
import { TradeType, CurrencyAmount, Price, Native, Fraction } from '@plexswap/sdk-core';
import memoize from 'lodash/memoize.js';
import invariant3 from 'tiny-invariant';
import { ChainId } from '@plexswap/chains';
import { z } from 'zod';
import debug from 'debug';

// Aether/index.ts
var Aether_exports = {};
__export(Aether_exports, {
  APISchema: () => schema_exports,
  Transformer: () => transformer_exports,
  getBestTrade: () => getBestTrade2,
  getExtendedCandidatePools: () => getExtendedCandidatePools,
  getExtendedPools: () => getExtendedPools,
  log: () => log,
  logger: () => logger,
  metric: () => metric
});

// config/abis/ITickLens.ts
var tickLensAbi = [
  {
    inputs: [
      { internalType: "address", name: "pool", type: "address" },
      { internalType: "int16", name: "tickBitmapIndex", type: "int16" }
    ],
    name: "getPopulatedTicksInWord",
    outputs: [
      {
        components: [
          { internalType: "int24", name: "tick", type: "int24" },
          { internalType: "int128", name: "liquidityNet", type: "int128" },
          { internalType: "uint128", name: "liquidityGross", type: "uint128" }
        ],
        internalType: "struct ITickLens.PopulatedTick[]",
        name: "populatedTicks",
        type: "tuple[]"
      }
    ],
    stateMutability: "view",
    type: "function"
  }
];

// Aether/constants/extendedPoolFetchGasLimit.ts
var DEFAULT_FETCH_CONFIG = {
  gasLimit: 3000000n,
  retryGasMultiplier: 2
};
var EXTENDED_POOL_FETCH_CONFIG = {};
function getExtendedPoolFetchConfig(chainId) {
  return EXTENDED_POOL_FETCH_CONFIG[chainId] || DEFAULT_FETCH_CONFIG;
}

// Aether/queries/getExtendedPools.ts
async function getExtendedCandidatePools({
  currencyA,
  currencyB,
  clientProvider,
  gasLimit
}) {
  const pairs = getPairCombinations(currencyA, currencyB);
  return getExtendedPools({ pairs, clientProvider, gasLimit });
}
async function getExtendedPools({ pairs, clientProvider, gasLimit }) {
  const pools = await getExtendedPoolsWithoutTicksOnChain(pairs || [], clientProvider);
  if (!pools.length) {
    return pools;
  }
  return fillPoolsWithTicks({ pools, clientProvider, gasLimit });
}
function getBitmapIndex(tick, tickSpacing) {
  return Math.floor(tick / tickSpacing / 256);
}
function createBitmapIndexListBuilder(tickRange) {
  return function buildBitmapIndexList2({ currentTick, fee, ...rest }) {
    const tickSpacing = TICK_SPACINGS[fee];
    const minIndex = getBitmapIndex(currentTick - tickRange, tickSpacing);
    const maxIndex = getBitmapIndex(currentTick + tickRange, tickSpacing);
    return Array.from(Array(maxIndex - minIndex + 1), (_, i) => ({
      bitmapIndex: minIndex + i,
      ...rest
    }));
  };
}
var buildBitmapIndexList = createBitmapIndexListBuilder(1e3);
async function fillPoolsWithTicks({ pools, clientProvider, gasLimit }) {
  const chainId = pools[0]?.token0.chainId;
  const tickLensAddress = EXTENDED_TICK_LENS_ADDRESSES[chainId];
  const client = clientProvider?.({ chainId });
  if (!client || !tickLensAddress) {
    throw new Error("Fill pools with ticks failed. No valid public client or tick lens found.");
  }
  const { gasLimit: gasLimitPerCall, retryGasMultiplier } = getExtendedPoolFetchConfig(chainId);
  const bitmapIndexes = pools.map(({ tick, fee }, i) => buildBitmapIndexList({ currentTick: tick, fee, poolIndex: i })).reduce((acc, cur) => [...acc, ...cur], []);
  const res = await multicallByGasLimit(
    bitmapIndexes.map(({ poolIndex, bitmapIndex }) => ({
      target: tickLensAddress,
      callData: encodeFunctionData({
        abi: tickLensAbi,
        args: [pools[poolIndex].address, bitmapIndex],
        functionName: "getPopulatedTicksInWord"
      }),
      gasLimit: gasLimitPerCall
    })),
    {
      chainId,
      client,
      gasLimit,
      retryFailedCallsWithGreaterLimit: {
        gasLimitMultiplier: retryGasMultiplier
      }
    }
  );
  const poolsWithTicks = pools.map((p) => ({ ...p }));
  for (const [index, result] of res.results.entries()) {
    const { poolIndex } = bitmapIndexes[index];
    const pool = poolsWithTicks[poolIndex];
    const data = result.success ? decodeFunctionResult({
      abi: tickLensAbi,
      functionName: "getPopulatedTicksInWord",
      data: result.result
    }) : void 0;
    const newTicks = data?.map(
      ({ tick, liquidityNet, liquidityGross }) => new Tick({
        index: tick,
        liquidityNet,
        liquidityGross
      })
    ).reverse();
    if (!newTicks) {
      continue;
    }
    pool.ticks = [...pool.ticks || [], ...newTicks];
  }
  return poolsWithTicks.filter((p) => p.ticks?.length);
}
function estimateGasCost({ pool, poolAfter }) {
  if (isCorePool(pool)) {
    return BASE_SWAP_COST_CORE;
  }
  if (isExtendedPool(pool) && isExtendedPool(poolAfter)) {
    const { ticks, token0, tick } = pool;
    invariant3(ticks !== void 0, "[Estimate gas]: No valid tick list found");
    const { tick: tickAfter } = poolAfter;
    const { chainId } = token0;
    const numOfTicksCrossed = TickList.countInitializedTicksCrossed(ticks, tick, tickAfter);
    const tickGasUse = COST_PER_INIT_TICK(chainId) * BigInt(numOfTicksCrossed);
    return BASE_SWAP_COST_EXTENDED(chainId) + COST_PER_HOP_EXTENDED(chainId) + tickGasUse;
  }
  if (isStablePool(pool)) {
    return BASE_SWAP_COST_STABLE_SWAP;
  }
  throw new Error("[Estimate gas]: Unknown pool type");
}

// Aether/pool.ts
function getCurrencyPairs(p) {
  if (isExtendedPool(p)) {
    return [[p.token0, p.token1]];
  }
  if (isCorePool(p)) {
    return [[p.reserve0.currency, p.reserve1.currency]];
  }
  if (isStablePool(p)) {
    const currencies = p.balances.map((b) => b.currency);
    const pairs = [];
    for (let i = 0; i < currencies.length; i++) {
      for (let j = i + 1; j < currencies.length; j++) {
        pairs.push([currencies[i], currencies[j]]);
      }
    }
    return pairs;
  }
  throw new Error("[Get currency pairs]: Unknown pool type");
}
function getReserve(p, currency) {
  if (isExtendedPool(p)) {
    return p.token0.equals(currency.wrapped) ? p.reserve0 : p.reserve1;
  }
  if (isCorePool(p)) {
    return p.reserve0.currency.wrapped.equals(currency.wrapped) ? p.reserve0 : p.reserve1;
  }
  if (isStablePool(p)) {
    return p.balances.find((b) => b.currency.wrapped.equals(currency.wrapped));
  }
  throw new Error("[Get reserve]: Unknown pool type");
}

// Aether/utils/groupPoolsPyType.ts
function groupPoolsByType(pools) {
  const poolsByType = /* @__PURE__ */ new Map();
  for (const pool of pools) {
    poolsByType.set(pool.type, [...poolsByType.get(pool.type) || [], pool]);
  }
  return Array.from(poolsByType.values());
}
function getBetterTrade(tradeA, tradeB) {
  if (!tradeA && !tradeB)
    return void 0;
  if (!tradeA && tradeB)
    return tradeB;
  if (tradeA && !tradeB)
    return tradeA;
  const isExactIn = tradeA.tradeType === TradeType.EXACT_INPUT;
  if (isExactIn) {
    if (tradeB.outputAmountWithGasAdjusted.greaterThan(tradeA.outputAmountWithGasAdjusted)) {
      return tradeB;
    }
    return tradeA;
  }
  if (tradeB.inputAmountWithGasAdjusted.lessThan(tradeA.inputAmountWithGasAdjusted)) {
    return tradeB;
  }
  return tradeA;
}

// Aether/graph/edge.ts
function getNeighbour(e, v) {
  return e.vertice0.currency.equals(v.currency) ? e.vertice1 : e.vertice0;
}
function createPriceCalculator({ graph, quote, gasPriceWei }) {
  const { chainId } = quote.currency;
  const priceMap = /* @__PURE__ */ new Map();
  const processedVert = /* @__PURE__ */ new Set();
  const edgeWeight = /* @__PURE__ */ new Map();
  let nextEdges = [];
  const getWeight = (e) => {
    const weight = edgeWeight.get(e);
    if (weight === void 0) {
      throw new Error(`Invalid weight for edge ${e}`);
    }
    return weight;
  };
  priceMap.set(quote, new Price(quote.currency, quote.currency, 1, 1));
  nextEdges = getNextEdges(quote);
  processedVert.add(quote);
  while (nextEdges.length) {
    const bestEdge = nextEdges.pop();
    const [vFrom, vTo] = processedVert.has(bestEdge.vertice1) ? [bestEdge.vertice1, bestEdge.vertice0] : [bestEdge.vertice0, bestEdge.vertice1];
    if (processedVert.has(vTo))
      continue;
    const p = getTokenPrice(bestEdge.pool, vTo.currency, vFrom.currency);
    const vFromQuotePrice = getQuotePrice(vFrom);
    invariant3(vFromQuotePrice !== void 0, "Invalid quote price");
    priceMap.set(vTo, p.multiply(vFromQuotePrice));
    nextEdges = getNextEdges(vTo);
    processedVert.add(vTo);
  }
  const native = Native.onChain(chainId).wrapped;
  const nativeVertice = graph.getVertice(native);
  if (!nativeVertice) {
    throw new Error("No valid native currency price found");
  }
  const nativePriceInQuote = getQuotePrice(nativeVertice);
  const gasPriceInQuote = nativePriceInQuote?.quote(CurrencyAmount.fromRawAmount(native, gasPriceWei));
  if (!gasPriceInQuote) {
    throw new Error("Failed to get gas price in quote");
  }
  function getNextEdges(v) {
    const price = priceMap.get(v);
    if (!price) {
      throw new Error("Invalid price");
    }
    const newEdges = v.edges.filter((e) => {
      if (processedVert.has(getNeighbour(e, v))) {
        return false;
      }
      const tokenReserve = getReserve(e.pool, v.currency);
      invariant3(tokenReserve !== void 0, "Unexpected empty token reserve");
      const liquidity = price.quote(tokenReserve).quotient;
      edgeWeight.set(e, liquidity);
      return true;
    });
    newEdges.sort((e1, e2) => Number(getWeight(e1) - getWeight(e2)));
    const res = [];
    while (nextEdges.length && newEdges.length) {
      if (getWeight(nextEdges[0]) < getWeight(newEdges[0])) {
        res.push(nextEdges.shift());
      } else {
        res.push(newEdges.shift());
      }
    }
    return [...res, ...nextEdges, ...newEdges];
  }
  function getPrice(base, targetQuote) {
    const basePrice = getQuotePrice(base);
    const quotePrice = getQuotePrice(targetQuote);
    return basePrice && quotePrice ? basePrice.multiply(quotePrice.invert()) : void 0;
  }
  function getQuotePrice(base) {
    return priceMap.get(base);
  }
  function getGasPriceInBase(base) {
    const basePrice = getQuotePrice(base);
    return gasPriceInQuote ? basePrice?.invert().quote(gasPriceInQuote) : void 0;
  }
  return {
    graph,
    getGasPriceInBase,
    getQuotePrice,
    getPrice
  };
}

// Aether/graph/route.ts
function isSameRoute(one, another) {
  if (one.pools.length !== another.pools.length) {
    return false;
  }
  for (const [index, p] of one.pools.entries()) {
    if (p.type !== another.pools[index].type) {
      return false;
    }
    if (getPoolAddress(p) !== getPoolAddress(another.pools[index])) {
      return false;
    }
  }
  return true;
}
function mergeRoute(one, another) {
  return {
    ...one,
    inputAmount: one.inputAmount.add(another.inputAmount),
    outputAmount: one.outputAmount.add(another.outputAmount),
    gasUseEstimateQuote: another.gasUseEstimateQuote ? one.gasUseEstimateQuote?.add(another.gasUseEstimateQuote) : one.gasUseEstimateQuote,
    gasUseEstimate: one.gasUseEstimate + another.gasUseEstimate,
    inputAmountWithGasAdjusted: another.inputAmountWithGasAdjusted ? one.inputAmountWithGasAdjusted?.add(another.inputAmountWithGasAdjusted) : one.inputAmountWithGasAdjusted,
    outputAmountWithGasAdjusted: another.outputAmountWithGasAdjusted ? one.outputAmountWithGasAdjusted?.add(another.outputAmountWithGasAdjusted) : one.outputAmountWithGasAdjusted,
    percent: one.percent + another.percent
  };
}

// Aether/graph/graph.ts
function getEdgeKey(p, vertA, vertB) {
  const [vert0, vert1] = vertA.currency.wrapped.sortsBefore(vertB.currency.wrapped) ? [vertA, vertB] : [vertB, vertA];
  return `${vert0.currency.chainId}-${vert0.currency.wrapped.address}-${vert1.currency.wrapped.address}-${getPoolAddress(p)}`;
}
function cloneGraph(graph) {
  const pools = graph.edges.map((e) => e.pool);
  return createGraph({ pools });
}
function createGraph({ pools, graph }) {
  if (graph) {
    return cloneGraph(graph);
  }
  if (!pools) {
    throw new Error("[Create graph]: Invalid pools");
  }
  const verticeMap = /* @__PURE__ */ new Map();
  const edgeMap = /* @__PURE__ */ new Map();
  for (const p of pools) {
    const pairs = getCurrencyPairs(p);
    for (const [currency0, currency1] of pairs) {
      const vertice0 = createVertice(currency0);
      const vertice1 = createVertice(currency1);
      const edge = createEdge(p, vertice0, vertice1);
      if (!vertice0.edges.includes(edge))
        vertice0.edges.push(edge);
      if (!vertice1.edges.includes(edge))
        vertice1.edges.push(edge);
    }
  }
  function getVertice(c) {
    return verticeMap.get(c.wrapped.address);
  }
  function getEdge(p, vert0, vert1) {
    return edgeMap.get(getEdgeKey(p, vert0, vert1));
  }
  function createVertice(c) {
    const vert = getVertice(c);
    if (vert) {
      return vert;
    }
    const vertice = { currency: c, edges: [] };
    verticeMap.set(c.wrapped.address, vertice);
    return vertice;
  }
  function createEdge(p, vertice0, vertice1) {
    const edgeKey = getEdgeKey(p, vertice0, vertice1);
    if (!edgeKey) {
      throw new Error(`Create edge failed. Cannot get valid edge key for ${p}`);
    }
    const e = edgeMap.get(edgeKey);
    if (e) {
      return e;
    }
    const edge = {
      vertice0,
      vertice1,
      pool: p
    };
    edgeMap.set(edgeKey, edge);
    return edge;
  }
  const hasValidRouteToVerticeWithinHops = memoize(
    (vertice, target, hops, visitedVertices) => {
      if (vertice === target) {
        return true;
      }
      if (hops <= 0) {
        return false;
      }
      const visited = visitedVertices || /* @__PURE__ */ new Set();
      visited.add(vertice);
      for (const edge of vertice.edges) {
        const nextVertice = getNeighbour(edge, vertice);
        if (nextVertice && !visited.has(nextVertice)) {
          if (hasValidRouteToVerticeWithinHops(nextVertice, target, hops - 1, visited)) {
            return true;
          }
        }
      }
      return false;
    },
    (v1, v2, hops) => `${v1.currency.chainId}-${v1.currency.wrapped.address}-${v2.currency.wrapped.address}-${hops}`
  );
  return {
    vertices: Array.from(verticeMap.values()),
    edges: Array.from(edgeMap.values()),
    getVertice,
    getEdge,
    hasValidRouteToVerticeWithinHops,
    applySwap: async ({ isExactIn, route }) => {
      const getPoolQuote = createPoolQuoteGetter(isExactIn);
      function* loopPools() {
        let i = isExactIn ? 0 : route.pools.length - 1;
        const getNext = () => isExactIn ? i + 1 : i - 1;
        const hasNext = isExactIn ? () => i < route.pools.length : () => i >= 0;
        for (; hasNext(); i = getNext()) {
          yield i;
        }
      }
      const amount = isExactIn ? route.inputAmount : route.outputAmount;
      invariant3(amount !== void 0, "[Apply swap]: Invalid base amount");
      let quote = amount;
      let gasCost = 0n;
      for (const i of loopPools()) {
        const vertA = getVertice(route.path[i]);
        const vertB = getVertice(route.path[i + 1]);
        const p = route.pools[i];
        invariant3(
          vertA !== void 0 && vertB !== void 0 && p !== void 0,
          "[Apply swap]: Invalid vertice and pool"
        );
        const edge = getEdge(p, vertA, vertB);
        invariant3(edge !== void 0, "[Apply swap]: No valid edge found");
        const quoteResult = await getPoolQuote(edge.pool, quote);
        invariant3(quoteResult !== void 0, "[Apply swap]: Failed to get quote");
        edge.pool = quoteResult.poolAfter;
        quote = quoteResult.quote;
        gasCost += estimateGasCost(quoteResult);
      }
      return {
        amount,
        quote,
        gasCost
      };
    }
  };
}
function getStreamedAmounts(amount, streams) {
  const streamSum = Array.isArray(streams) ? streams.reduce((sum2, s) => sum2 + s, 0) : streams;
  const streamDistributions = Array.isArray(streams) ? streams.map((stream) => new Fraction(stream, streamSum)) : Array.from({ length: streams }).map(() => new Fraction(1, streamSum));
  const numOfStreams = streamDistributions.length;
  let sum = new Fraction(0);
  const amounts = [];
  for (const [index, stream] of streamDistributions.entries()) {
    const streamAmount = index === numOfStreams - 1 ? amount.subtract(amount.multiply(sum)) : amount.multiply(stream);
    const revisedStream = index === numOfStreams - 1 ? new Fraction(1).subtract(sum) : stream;
    const percent = Number(formatFraction(revisedStream) || 0) * 100;
    amounts.push({ amount: streamAmount, percent });
    sum = sum.add(stream);
  }
  return amounts;
}
var getGasCostInCurrency = ({
  priceCalculator,
  gasCost,
  currency
}) => {
  const v = priceCalculator.graph.getVertice(currency);
  const gasPriceInCurrency = v && priceCalculator.getGasPriceInBase(v);
  const gasCostInCurrencyRaw = gasPriceInCurrency?.multiply(gasCost);
  return CurrencyAmount.fromRawAmount(currency, gasCostInCurrencyRaw?.quotient || 0);
};
async function findBestTrade(params) {
  const { tradeType, candidatePools, ...rest } = params;
  const isExactIn = tradeType === TradeType.EXACT_INPUT;
  if (isExactIn) {
    return getBestTrade(params);
  }
  const poolsByType = groupPoolsByType(candidatePools).filter((pools) => !isStablePool(pools[0]));
  const trades = await Promise.allSettled(
    poolsByType.map((pools) => getBestTrade({ tradeType, candidatePools: pools, ...rest }))
  );
  let bestTrade;
  for (const result of trades) {
    if (result.status === "rejected") {
      continue;
    }
    const { value: trade } = result;
    if (!trade) {
      continue;
    }
    if (!bestTrade) {
      bestTrade = trade;
      continue;
    }
    bestTrade = getBetterTrade(bestTrade, trade);
  }
  return bestTrade;
}
async function getBestTrade({
  amount: totalAmount,
  candidatePools,
  quoteCurrency,
  gasPriceWei,
  streams,
  graph: graphOverride,
  tradeType,
  maxHops = 3
}) {
  const isExactIn = tradeType === TradeType.EXACT_INPUT;
  const baseCurrency = totalAmount.currency;
  const inputCurrency = isExactIn ? baseCurrency : quoteCurrency;
  const outputCurrency = isExactIn ? quoteCurrency : baseCurrency;
  const graph = graphOverride || createGraph({ pools: candidatePools });
  const amounts = getStreamedAmounts(totalAmount, streams);
  const getPoolQuote = createPoolQuoteGetter(isExactIn);
  const gasPrice = BigInt(typeof gasPriceWei === "function" ? await gasPriceWei() : gasPriceWei);
  const start = graph.getVertice(baseCurrency);
  const finish = graph.getVertice(quoteCurrency);
  if (!start || !finish) {
    throw new Error(`Invalid start vertice or finish vertice. Start ${start}, finish ${finish}`);
  }
  const priceCalculator = createPriceCalculator({ quote: start, gasPriceWei: gasPrice, graph });
  const adjustQuoteByGas = (quote, gasCostInQuote) => isExactIn ? quote.subtract(gasCostInQuote) : quote.add(gasCostInQuote);
  const isQuoteBetter = (quote, compareTo) => isExactIn ? quote.greaterThan(compareTo) : quote.lessThan(compareTo);
  const getInputAmount = (amount, quote) => isExactIn ? amount : quote;
  const getOutputAmount = (amount, quote) => isExactIn ? quote : amount;
  const getQuoteAmount = (r) => isExactIn ? r.outputAmount : r.inputAmount;
  const buildTrade = (g, routes2) => {
    const {
      gasUseEstimate,
      quoteAmount,
      gasUseEstimateQuote,
      gasUseEstimateBase,
      inputAmountWithGasAdjusted,
      outputAmountWithGasAdjusted
    } = routes2.reduce(
      (result, r) => {
        return {
          gasUseEstimate: result.gasUseEstimate + r.gasUseEstimate,
          quoteAmount: result.quoteAmount.add(getQuoteAmount(r)),
          gasUseEstimateBase: result.gasUseEstimateBase.add(r.gasUseEstimateBase),
          gasUseEstimateQuote: result.gasUseEstimateQuote.add(r.gasUseEstimateQuote),
          inputAmountWithGasAdjusted: result.inputAmountWithGasAdjusted.add(r.inputAmountWithGasAdjusted),
          outputAmountWithGasAdjusted: result.outputAmountWithGasAdjusted.add(r.outputAmountWithGasAdjusted)
        };
      },
      {
        gasUseEstimate: 0n,
        quoteAmount: CurrencyAmount.fromRawAmount(quoteCurrency, 0),
        gasUseEstimateBase: CurrencyAmount.fromRawAmount(baseCurrency, 0),
        gasUseEstimateQuote: CurrencyAmount.fromRawAmount(quoteCurrency, 0),
        inputAmountWithGasAdjusted: CurrencyAmount.fromRawAmount(inputCurrency, 0),
        outputAmountWithGasAdjusted: CurrencyAmount.fromRawAmount(outputCurrency, 0)
      }
    );
    return {
      gasUseEstimate,
      gasUseEstimateQuote,
      gasUseEstimateBase,
      inputAmountWithGasAdjusted,
      outputAmountWithGasAdjusted,
      inputAmount: getInputAmount(totalAmount, quoteAmount),
      outputAmount: getOutputAmount(totalAmount, quoteAmount),
      tradeType,
      graph: g,
      routes: routes2
    };
  };
  async function findBestRoute(amount) {
    invariant3(start !== void 0 && finish !== void 0, "Invalid start/finish vertice");
    const bestResult = /* @__PURE__ */ new Map();
    const processedVert = /* @__PURE__ */ new Set();
    bestResult.set(start, {
      hops: 0,
      bestAmount: amount,
      gasSpent: 0n
    });
    const nextVertList = [start];
    const getHops = (vert) => bestResult.get(vert)?.hops || 0;
    const getBestAmount = (vert) => bestResult.get(vert)?.bestAmount;
    const getBestQuote = (vert) => bestResult.get(vert)?.bestQuote;
    const getBestSource = (vert) => bestResult.get(vert)?.bestSource;
    const getGasSpent = (vert) => bestResult.get(vert)?.gasSpent || 0n;
    const getNextVert = () => {
      let vert;
      let bestQuote;
      let bestVertIndex;
      for (const [i, vertice] of nextVertList.entries()) {
        const currentBestQuote = getBestQuote(vertice);
        if (vert === void 0 || bestQuote === void 0 || currentBestQuote && isQuoteBetter(currentBestQuote, bestQuote)) {
          vert = vertice;
          bestQuote = currentBestQuote;
          bestVertIndex = i;
        }
      }
      return { vert, index: bestVertIndex };
    };
    const getBestRoute = (vert) => {
      const pools = [];
      const path = [vert.currency];
      for (let v = finish; getBestSource(v); v = getNeighbour(getBestSource(v), v)) {
        const bestSource = getBestSource(v);
        invariant3(bestSource !== void 0, "Invalid best source");
        const neighbour = getNeighbour(bestSource, v);
        if (isExactIn) {
          pools.unshift(bestSource.pool);
          path.unshift(neighbour?.currency);
        } else {
          pools.push(bestSource.pool);
          path.push(neighbour?.currency);
        }
      }
      const gasCost = getGasSpent(vert);
      const quoteAmountRaw = getBestAmount(vert);
      invariant3(quoteAmountRaw !== void 0, "Invalid quote amount");
      const quoteAmount = CurrencyAmount.fromRawAmount(quoteCurrency, quoteAmountRaw.quotient);
      const gasUseEstimateBase = getGasCostInCurrency({ priceCalculator, gasCost, currency: baseCurrency });
      const gasUseEstimateQuote = getGasCostInCurrency({ priceCalculator, gasCost, currency: quoteCurrency });
      const quoteAmountWithGasAdjusted = CurrencyAmount.fromRawAmount(
        quoteCurrency,
        gasUseEstimateQuote ? adjustQuoteByGas(quoteAmount, gasUseEstimateQuote).quotient : 0
      );
      const { type } = buildBaseRoute(pools, start.currency, finish.currency);
      return {
        type,
        path,
        pools,
        gasUseEstimate: gasCost,
        inputAmount: getInputAmount(amount, quoteAmount),
        outputAmount: getOutputAmount(amount, quoteAmount),
        inputAmountWithGasAdjusted: getInputAmount(amount, quoteAmountWithGasAdjusted),
        outputAmountWithGasAdjusted: getOutputAmount(amount, quoteAmountWithGasAdjusted),
        gasUseEstimateQuote,
        gasUseEstimateBase
      };
    };
    for (; ; ) {
      const { vert, index } = getNextVert();
      if (!vert || index === void 0)
        return void 0;
      if (vert === finish) {
        return getBestRoute(vert);
      }
      nextVertList.splice(index, 1);
      const currentHop = getHops(vert);
      for (const e of vert.edges) {
        const v2 = vert === e.vertice0 ? e.vertice1 : e.vertice0;
        if (processedVert.has(v2))
          continue;
        if (!graph.hasValidRouteToVerticeWithinHops(v2, finish, maxHops - currentHop - 1)) {
          continue;
        }
        try {
          const bestAmount = getBestAmount(vert);
          invariant3(bestAmount !== void 0, "Invalid amount");
          const quoteResult = await getPoolQuote(e.pool, bestAmount);
          invariant3(quoteResult !== void 0, "Invalid quote result");
          const { quote } = quoteResult;
          const gasPriceInV2 = priceCalculator.getGasPriceInBase(v2);
          invariant3(gasPriceInV2 !== void 0, "Invalid gas price in v2");
          const gasSpent = estimateGasCost(quoteResult) + getGasSpent(vert);
          const price = priceCalculator.getPrice(v2, finish);
          invariant3(
            price !== void 0,
            `Failed to get price, base ${v2.currency.symbol}, quote ${finish.currency.symbol}`
          );
          const gasSpentInQuote = price.quote(gasPriceInV2.multiply(gasSpent));
          const newQuote = adjustQuoteByGas(price.quote(quote), gasSpentInQuote);
          const bestSource = getBestSource(v2);
          const v2BestQuote = getBestQuote(v2);
          if (!bestSource)
            nextVertList.push(v2);
          if (!bestSource || !v2BestQuote || isQuoteBetter(newQuote, v2BestQuote)) {
            bestResult.set(v2, {
              hops: currentHop + 1,
              gasSpent,
              bestAmount: quote,
              bestSource: e,
              bestQuote: newQuote
            });
          }
        } catch (_err) {
          continue;
        }
      }
      processedVert.add(vert);
    }
  }
  let routeMerged = false;
  const routes = [];
  for (const { amount, percent } of amounts) {
    const route = await findBestRoute(amount);
    invariant3(
      route !== void 0,
      `No valid route found for base amount ${amount.toExact()} ${amount.currency.symbol} and quote currency ${quoteCurrency.symbol}`
    );
    await graph.applySwap({ route, isExactIn });
    const newRoute = {
      ...route,
      percent
    };
    const index = routes.findIndex((r) => isSameRoute(r, newRoute));
    if (index < 0) {
      routes.push(newRoute);
    } else {
      routes[index] = mergeRoute(routes[index], newRoute);
      routeMerged = true;
    }
  }
  if (!routes.length) {
    return void 0;
  }
  if (!routeMerged) {
    return buildTrade(graph, routes);
  }
  const finalGraph = createGraph({ graph: graphOverride, pools: candidatePools });
  const s = finalGraph.getVertice(baseCurrency);
  invariant3(s !== void 0, "[Graph rebuild]: Invalid start vertice");
  const pc = createPriceCalculator({ quote: s, gasPriceWei: gasPrice, graph: finalGraph });
  const finalRoutes = [];
  for (const r of routes) {
    const { amount, quote: quoteRaw, gasCost } = await finalGraph.applySwap({ isExactIn, route: r });
    const quote = CurrencyAmount.fromRawAmount(quoteCurrency, quoteRaw.quotient);
    const gasUseEstimateBase = getGasCostInCurrency({ priceCalculator: pc, gasCost, currency: baseCurrency });
    const gasUseEstimateQuote = getGasCostInCurrency({ priceCalculator: pc, gasCost, currency: quoteCurrency });
    const quoteAmountWithGasAdjusted = CurrencyAmount.fromRawAmount(
      quoteCurrency,
      gasUseEstimateQuote ? adjustQuoteByGas(quote, gasUseEstimateQuote).quotient : 0
    );
    finalRoutes.push({
      ...r,
      gasUseEstimate: gasCost,
      inputAmount: getInputAmount(amount, quote),
      outputAmount: getOutputAmount(amount, quote),
      inputAmountWithGasAdjusted: getInputAmount(amount, quoteAmountWithGasAdjusted),
      outputAmountWithGasAdjusted: getOutputAmount(amount, quoteAmountWithGasAdjusted),
      gasUseEstimateQuote,
      gasUseEstimateBase
    });
  }
  return buildTrade(finalGraph, finalRoutes);
}

// Aether/trade.ts
var DEFAULT_STREAM = 10;
function getBestStreamsConfig(trade) {
  const maxStreams = 100;
  if (!trade) {
    return DEFAULT_STREAM;
  }
  const priceImpact = getPriceImpact(trade);
  if (!priceImpact) {
    return DEFAULT_STREAM;
  }
  const { gasUseEstimateBase, inputAmount, outputAmount } = trade;
  if (!gasUseEstimateBase) {
    return DEFAULT_STREAM;
  }
  const amount = trade.tradeType === TradeType.EXACT_INPUT ? inputAmount : outputAmount;
  const bestFlowAmount = Math.sqrt(
    Number(gasUseEstimateBase.toExact()) * Number(amount.toExact()) / Number(formatFraction(priceImpact.asFraction))
  );
  const streams = Math.round(Number(amount.toExact()) / bestFlowAmount);
  if (!Number.isFinite(streams)) {
    return DEFAULT_STREAM;
  }
  return Math.max(1, Math.min(streams, maxStreams));
}
async function getBestTrade2(amount, quoteCurrency, tradeType, { candidatePools, gasPriceWei, maxHops, maxSplits }) {
  const splitDisabled = maxSplits !== void 0 && maxSplits === 0;
  let bestTrade;
  try {
    bestTrade = await findBestTrade({
      tradeType,
      amount,
      quoteCurrency,
      gasPriceWei,
      candidatePools,
      maxHops,
      streams: 1
    });
  } catch (e) {
    if (splitDisabled) {
      throw e;
    }
    bestTrade = await findBestTrade({
      tradeType,
      amount,
      quoteCurrency,
      gasPriceWei,
      candidatePools,
      maxHops,
      streams: DEFAULT_STREAM
    });
  }
  if (splitDisabled) {
    return bestTrade;
  }
  const streams = getBestStreamsConfig(bestTrade);
  if (streams === 1) {
    return bestTrade;
  }
  const bestTradeWithStreams = await findBestTrade({
    tradeType,
    amount,
    quoteCurrency,
    gasPriceWei,
    candidatePools,
    maxHops,
    streams
  });
  return getBetterTrade(bestTrade, bestTradeWithStreams);
}

// Aether/schema.ts
var schema_exports = {};
__export(schema_exports, {
  zPools: () => zPools,
  zRouterPostParams: () => zRouterPostParams
});
var zChainId = z.nativeEnum(ChainId);
var zFee = z.nativeEnum(FeeAmount);
var zTradeType = z.nativeEnum(TradeType);
var zAddress = z.custom((val) => /^0x[a-fA-F0-9]{40}$/.test(val));
var zBigNumber = z.string().regex(/^[0-9]+$/);
var zSignedBigInt = z.string().regex(/^-?[0-9]+$/);
var zCurrency = z.object({
  address: zAddress,
  decimals: z.number(),
  symbol: z.string()
}).required();
var zTick = z.object({
  index: z.number(),
  liquidityGross: zSignedBigInt,
  liquidityNet: zSignedBigInt
}).required();
var zCurrencyAmountBase = z.object({
  currency: zCurrency.required(),
  value: zBigNumber
});
var zCurrencyAmountOptional = zCurrencyAmountBase.optional();
var zCurrencyAmount = zCurrencyAmountBase.required();
var zCorePool = z.object({
  type: z.literal(0 /* CORE */),
  reserve0: zCurrencyAmount,
  reserve1: zCurrencyAmount
}).required();
var zExtendedPool = z.object({
  type: z.literal(1 /* EXTENDED */),
  token0: zCurrency,
  token1: zCurrency,
  fee: zFee,
  liquidity: zBigNumber,
  sqrtRatioX96: zBigNumber,
  tick: z.number(),
  address: zAddress,
  token0ProtocolFee: z.string(),
  token1ProtocolFee: z.string(),
  reserve0: zCurrencyAmountOptional,
  reserve1: zCurrencyAmountOptional,
  ticks: z.array(zTick).optional()
}).required({
  type: true,
  token0: true,
  token1: true,
  fee: true,
  liquidity: true,
  sqrtRatioX96: true,
  tick: true,
  address: true,
  token0ProtocolFee: true,
  token1ProtocolFee: true
});
var zStablePool = z.object({
  type: z.literal(2 /* STABLE */),
  balances: z.array(zCurrencyAmount),
  amplifier: zBigNumber,
  fee: z.string()
}).required();
var zPools = z.array(z.union([zExtendedPool, zCorePool, zStablePool]));
var zRouterPostParams = z.object({
  chainId: zChainId,
  tradeType: zTradeType,
  amount: zCurrencyAmount,
  currency: zCurrency,
  candidatePools: zPools,
  gasPriceWei: zBigNumber.optional(),
  maxHops: z.number().optional(),
  maxSplits: z.number().optional()
}).required({
  chainId: true,
  tradeType: true,
  amount: true,
  currency: true,
  candidatePools: true
});

// Aether/transformer.ts
var transformer_exports = {};
__export(transformer_exports, {
  parseRoute: () => parseRoute,
  parseTrade: () => parseTrade,
  serializeRoute: () => serializeRoute,
  serializeTrade: () => serializeTrade
});
function serializeRoute(route) {
  return {
    ...route,
    pools: route.pools.map(serializePool),
    path: route.path.map(serializeCurrency),
    inputAmount: serializeCurrencyAmount(route.inputAmount),
    outputAmount: serializeCurrencyAmount(route.outputAmount),
    gasUseEstimate: String(route.gasUseEstimate),
    gasUseEstimateBase: serializeCurrencyAmount(route.gasUseEstimateBase),
    gasUseEstimateQuote: serializeCurrencyAmount(route.gasUseEstimateQuote),
    inputAmountWithGasAdjusted: serializeCurrencyAmount(route.inputAmountWithGasAdjusted),
    outputAmountWithGasAdjusted: serializeCurrencyAmount(route.outputAmountWithGasAdjusted)
  };
}
function parseRoute(chainId, route) {
  return {
    ...route,
    pools: route.pools.map((p) => parsePool(chainId, p)),
    path: route.path.map((c) => parseCurrency(chainId, c)),
    inputAmount: parseCurrencyAmount(chainId, route.inputAmount),
    outputAmount: parseCurrencyAmount(chainId, route.outputAmount),
    gasUseEstimate: BigInt(route.gasUseEstimate),
    gasUseEstimateBase: parseCurrencyAmount(chainId, route.gasUseEstimateBase),
    gasUseEstimateQuote: parseCurrencyAmount(chainId, route.gasUseEstimateQuote),
    inputAmountWithGasAdjusted: parseCurrencyAmount(chainId, route.inputAmountWithGasAdjusted),
    outputAmountWithGasAdjusted: parseCurrencyAmount(chainId, route.outputAmountWithGasAdjusted)
  };
}
function serializeTrade(trade) {
  const { graph: _graph, ...rest } = trade;
  return {
    ...rest,
    inputAmount: serializeCurrencyAmount(trade.inputAmount),
    outputAmount: serializeCurrencyAmount(trade.outputAmount),
    routes: trade.routes.map(serializeRoute),
    gasUseEstimate: trade.gasUseEstimate.toString(),
    gasUseEstimateBase: serializeCurrencyAmount(trade.gasUseEstimateBase),
    gasUseEstimateQuote: serializeCurrencyAmount(trade.gasUseEstimateQuote),
    inputAmountWithGasAdjusted: serializeCurrencyAmount(trade.inputAmountWithGasAdjusted),
    outputAmountWithGasAdjusted: serializeCurrencyAmount(trade.outputAmountWithGasAdjusted)
  };
}
function parseTrade(chainId, trade) {
  return {
    ...trade,
    tradeType: trade.tradeType,
    inputAmount: parseCurrencyAmount(chainId, trade.inputAmount),
    outputAmount: parseCurrencyAmount(chainId, trade.outputAmount),
    routes: trade.routes.map((r) => parseRoute(chainId, r)),
    gasUseEstimate: trade.gasUseEstimate ? BigInt(trade.gasUseEstimate) : 0n,
    gasUseEstimateBase: parseCurrencyAmount(chainId, trade.gasUseEstimateBase),
    gasUseEstimateQuote: parseCurrencyAmount(chainId, trade.gasUseEstimateQuote),
    inputAmountWithGasAdjusted: parseCurrencyAmount(chainId, trade.inputAmountWithGasAdjusted),
    outputAmountWithGasAdjusted: parseCurrencyAmount(chainId, trade.outputAmountWithGasAdjusted)
  };
}
var SCOPE_PREFIX = "JWST";
var SCOPE = {
  metric: "metric",
  log: "log",
  error: "error"
};
var log_ = debug(SCOPE_PREFIX);
var metric = log_.extend(SCOPE.metric);
var log = log_.extend(SCOPE.log);
var logger = {
  metric,
  log,
  error: debug(SCOPE_PREFIX).extend(SCOPE.error),
  enable: (namespace) => {
    let namespaces = namespace;
    if (namespace.includes(",")) {
      namespaces = namespace.split(",").map((ns) => `${SCOPE_PREFIX}:${ns}`).join(",");
    } else {
      namespaces = `${SCOPE_PREFIX}:${namespace}`;
    }
    debug.enable(namespaces);
  }
};

export { Aether_exports, getBestTrade2 as getBestTrade, getExtendedCandidatePools, getExtendedPools, log, logger, metric, schema_exports, transformer_exports };
