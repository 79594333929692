// src/chainId.ts
var ChainId = /* @__PURE__ */ ((ChainId2) => {
  ChainId2[ChainId2["BSC"] = 56] = "BSC";
  ChainId2[ChainId2["BSC_TESTNET"] = 97] = "BSC_TESTNET";
  ChainId2[ChainId2["PLEXCHAIN"] = 1149] = "PLEXCHAIN";
  return ChainId2;
})(ChainId || {});
var testnetChainIds = [
  97 /* BSC_TESTNET */
];

// src/chainNames.ts
var chainNames = {
  [56 /* BSC */]: "bsc",
  [97 /* BSC_TESTNET */]: "bscTestnet",
  [1149 /* PLEXCHAIN */]: "plexchain"
};
var chainNameToChainId = Object.entries(chainNames).reduce((acc, [chainId, chainName]) => {
  return {
    [chainName]: chainId,
    ...acc
  };
}, {});
var defiLlamaChainNames = {
  [56 /* BSC */]: "bsc",
  [97 /* BSC_TESTNET */]: "",
  [1149 /* PLEXCHAIN */]: ""
};
var chainNamesInKebabCase = {
  [1149 /* PLEXCHAIN */]: "plexchain",
  [56 /* BSC */]: "bsc",
  [97 /* BSC_TESTNET */]: "bsc-testnet"
};
var mainnetChainNamesInKebabCase = {
  [1149 /* PLEXCHAIN */]: "plexchain",
  [56 /* BSC */]: "bsc",
  [97 /* BSC_TESTNET */]: "bsc"
};

// ../../node_modules/.pnpm/viem@2.13.3_typescript@5.2.2_zod@3.22.4/node_modules/viem/_esm/utils/chain/defineChain.js
function defineChain(chain) {
  return {
    formatters: void 0,
    fees: void 0,
    serializers: void 0,
    ...chain
  };
}

// ../../node_modules/.pnpm/viem@2.13.3_typescript@5.2.2_zod@3.22.4/node_modules/viem/_esm/chains/definitions/bsc.js
var bsc = /* @__PURE__ */ defineChain({
  id: 56,
  name: "BNB Smart Chain",
  nativeCurrency: {
    decimals: 18,
    name: "BNB",
    symbol: "BNB"
  },
  rpcUrls: {
    default: { http: ["https://rpc.ankr.com/bsc"] }
  },
  blockExplorers: {
    default: {
      name: "BscScan",
      url: "https://bscscan.com",
      apiUrl: "https://api.bscscan.com/api"
    }
  },
  contracts: {
    multicall3: {
      address: "0xca11bde05977b3631167028862be2a173976ca11",
      blockCreated: 15921452
    }
  }
});

// ../../node_modules/.pnpm/viem@2.13.3_typescript@5.2.2_zod@3.22.4/node_modules/viem/_esm/chains/definitions/bscTestnet.js
var bscTestnet = /* @__PURE__ */ defineChain({
  id: 97,
  name: "Binance Smart Chain Testnet",
  nativeCurrency: {
    decimals: 18,
    name: "BNB",
    symbol: "tBNB"
  },
  rpcUrls: {
    default: { http: ["https://data-seed-prebsc-1-s1.bnbchain.org:8545"] }
  },
  blockExplorers: {
    default: {
      name: "BscScan",
      url: "https://testnet.bscscan.com",
      apiUrl: "https://testnet.bscscan.com/api"
    }
  },
  contracts: {
    multicall3: {
      address: "0xca11bde05977b3631167028862be2a173976ca11",
      blockCreated: 17422483
    }
  },
  testnet: true
});

// src/chainsConfig.ts
var plexchain = {
  id: 1149,
  name: "Plexchain",
  nativeCurrency: {
    decimals: 18,
    name: "Plexchain Native Token",
    symbol: "PLEX"
  },
  rpcUrls: {
    public: { http: ["https://plex-rpc.plexfinance.us/"] },
    default: { http: ["https://plex-rpc.plexfinance.us/"] }
  },
  blockExplorers: {
    default: { name: "PlexScan", url: "https://explorer.plexfinance.us" }
  },
  contracts: {
    multicall3: {
      address: "0x2210e34629E5B17B5F2D875a76098223d71F1D3E",
      blockCreated: 455863
    }
  }
};
var CHAINS = [bsc, bscTestnet, plexchain];

// src/utils.ts
function getChainName(chainId) {
  return chainNames[chainId];
}
function getChainNameInKebabCase(chainId) {
  return chainNamesInKebabCase[chainId];
}
function getMainnetChainNameInKebabCase(chainId) {
  return mainnetChainNamesInKebabCase[chainId];
}
function getLlamaChainName(chainId) {
  return defiLlamaChainNames[chainId];
}
function getChainIdByChainName(chainName) {
  if (!chainName)
    return void 0;
  return chainNameToChainId[chainName] ?? void 0;
}
var verifyBscNetwork = (chainId) => {
  return Boolean(chainId && (chainId === 56 /* BSC */ || chainId === 97 /* BSC_TESTNET */));
};
function verifyTestnet(chainId) {
  return testnetChainIds.includes(chainId);
}

// src/subgraphs.ts
var publicSubgraphParams = {
  // Public key for theGraph subgraph endpoint
  theGraphApiKey: "ae63266f663cff03919653f9ae3ade22"
};
var CORE_SUBGRAPHS = getCoreSubgraphs(publicSubgraphParams);
var EXTENDED_SUBGRAPHS = getExtendedSubgraphs(publicSubgraphParams);
var STABLESWAP_SUBGRAPHS = getStableSwapSubgraphs(publicSubgraphParams);
var BLOCKS_SUBGRAPHS = getBlocksSubgraphs();
function getCoreSubgraphs({ theGraphApiKey }) {
  return {
    [56 /* BSC */]: `https://gateway-arbitrum.network.thegraph.com/api/${theGraphApiKey}/subgraphs/id/5pb64kMj3aEefs1DqnhDtM94UBeiC6LNX6DD9NqKmJdy`
  };
}
function getExtendedSubgraphs({ theGraphApiKey }) {
  return {
    [56 /* BSC */]: `https://gateway-arbitrum.network.thegraph.com/api/${theGraphApiKey}/subgraphs/id/32MkDhGQ9Xjmf9LpStHuGCYiehCwPd8Sakm2TiuPYBUH`,
    [97 /* BSC_TESTNET */]: `https://gateway-arbitrum.network.thegraph.com/api/${theGraphApiKey}/subgraphs/id/DdBQekWhz9ksc4aaVkGeYnLMZsCuThNMMY9FBDK9Eq1X`
  };
}
function getStableSwapSubgraphs({ theGraphApiKey }) {
  return {
    [56 /* BSC */]: `https://gateway-arbitrum.network.thegraph.com/api/${theGraphApiKey}/subgraphs/id/HXCS3oK7MmjNs7hMkyH53LNkStaLWmsZprqjg54w2zRT`
  };
}
function getBlocksSubgraphs() {
  return {
    [56 /* BSC */]: "https://api.studio.thegraph.com/query/31296/plexblocks/version/latest"
  };
}

// src/chainBlockTimes.ts
var AVERAGE_CHAIN_BLOCK_TIMES = {
  [56 /* BSC */]: 3,
  [97 /* BSC_TESTNET */]: 3,
  [1149 /* PLEXCHAIN */]: 60
};

export { AVERAGE_CHAIN_BLOCK_TIMES, BLOCKS_SUBGRAPHS, CHAINS, CORE_SUBGRAPHS, ChainId, EXTENDED_SUBGRAPHS, STABLESWAP_SUBGRAPHS, bsc, bscTestnet, chainNameToChainId, chainNames, chainNamesInKebabCase, defiLlamaChainNames, getBlocksSubgraphs, getChainIdByChainName, getChainName, getChainNameInKebabCase, getCoreSubgraphs, getExtendedSubgraphs, getLlamaChainName, getMainnetChainNameInKebabCase, getStableSwapSubgraphs, mainnetChainNamesInKebabCase, plexchain, testnetChainIds, verifyBscNetwork, verifyTestnet };
