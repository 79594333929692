import { ChainId } from '@plexswap/chains';
import { plexchainTokens, bscTokens, bscTestnetTokens } from '@plexswap/tokens';

// config/constants/gasModel/core.ts
var BASE_SWAP_COST_CORE = 135000n;
var COST_PER_EXTRA_HOP_CORE = 50000n;
var COST_PER_UNINIT_TICK = 0n;
var BASE_SWAP_COST_EXTENDED = (id) => {
  switch (id) {
    case ChainId.BSC:
    case ChainId.BSC_TESTNET:
    case ChainId.PLEXCHAIN:
      return 2000n;
    default:
      return 0n;
  }
};
var COST_PER_INIT_TICK = (id) => {
  switch (id) {
    case ChainId.BSC:
    case ChainId.BSC_TESTNET:
    case ChainId.PLEXCHAIN:
      return 31000n;
    default:
      return 0n;
  }
};
var COST_PER_HOP_EXTENDED = (id) => {
  switch (id) {
    case ChainId.BSC:
    case ChainId.BSC_TESTNET:
    case ChainId.PLEXCHAIN:
      return 80000n;
    default:
      return 0n;
  }
};

// config/constants/gasModel/stableSwap.ts
var BASE_SWAP_COST_STABLE_SWAP = 180000n;
var COST_PER_EXTRA_HOP_STABLE_SWAP = 70000n;
var usdGasTokensByChain = {
  [ChainId.PLEXCHAIN]: [plexchainTokens.usdp],
  [ChainId.BSC]: [bscTokens.usdt],
  [ChainId.BSC_TESTNET]: [bscTestnetTokens.usdc]
};
var DEFAULT = {
  defaultConfig: {
    gasLimitPerCall: 1e6
  },
  gasErrorFailureOverride: {
    gasLimitPerCall: 2e6
  },
  successRateFailureOverrides: {
    gasLimitPerCall: 2e6
  }
};
var BATCH_MULTICALL_CONFIGS = {
  [ChainId.BSC_TESTNET]: DEFAULT,
  [ChainId.BSC]: DEFAULT,
  [ChainId.PLEXCHAIN]: DEFAULT
};

export { BASE_SWAP_COST_CORE, BASE_SWAP_COST_EXTENDED, BASE_SWAP_COST_STABLE_SWAP, BATCH_MULTICALL_CONFIGS, COST_PER_EXTRA_HOP_CORE, COST_PER_EXTRA_HOP_STABLE_SWAP, COST_PER_HOP_EXTENDED, COST_PER_INIT_TICK, COST_PER_UNINIT_TICK, usdGasTokensByChain };
