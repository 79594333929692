import { PlexMulticallProvider, transformer_exports, buildBaseRoute, corePoolTvlSelector, createCommonTokenPriceProvider, createCorePoolsProviderByCommonTokenPrices, createGetCoreCandidatePools, createGetExtendedCandidatePools, createHybridPoolProvider, createOffChainQuoteProvider, createPoolProvider, createPoolQuoteGetter, createQuoteProvider, createStaticPoolProvider, encodeMixedRouteToPath, extendedPoolTvlSelector, extendedPoolsOnChainProviderFactory, getAllExtendedPoolsFromSubgraph, getCandidatePools, getCheckAgainstBaseTokens, getCommonTokenPrices, getCommonTokenPricesByLlma, getCommonTokenPricesBySubgraph, getCommonTokenPricesByWalletApi, getCoreCandidatePools, getCorePoolSubgraph, getCorePoolsOnChain, getCorePoolsWithTvlByCommonTokenPrices, getExecutionPrice, getExtendedCandidatePools, getExtendedPoolSubgraph, getExtendedPoolsWithTvlFromOnChain, getExtendedPoolsWithTvlFromOnChainFallback, getExtendedPoolsWithTvlFromOnChainStaticFallback, getExtendedPoolsWithoutTicksOnChain, getMidPrice, getOutputOfPools, getPairCombinations, getPoolAddress, getPriceImpact, getStableCandidatePools, getStablePoolsOnChain, getTokenUsdPricesBySubgraph, involvesCurrency, isCorePool, isExtendedPool, isStablePool, log, logger, maximumAmountIn, metric, minimumAmountOut, partitionMixedRouteByProtocol, ROUTE_CONFIG_BY_CHAIN, computeAllRoutes, getBestRouteCombinationByQuotes, PoolType, getUsdGasToken, getNativeWrappedToken, getAmountDistribution, tryParseAmount_default, getTokenPrice } from './chunk-TFI73TPS.mjs';
import { BASE_SWAP_COST_EXTENDED, COST_PER_HOP_EXTENDED, BASE_SWAP_COST_STABLE_SWAP, COST_PER_EXTRA_HOP_STABLE_SWAP, COST_PER_INIT_TICK, BASE_SWAP_COST_CORE, COST_PER_EXTRA_HOP_CORE, COST_PER_UNINIT_TICK } from './chunk-7CJLLFMP.mjs';
import { __export, feeOnTransferDetectorAddresses } from './chunk-3YPNWKHB.mjs';
import { ZERO, TradeType, WNATIVE, Price, CurrencyAmount } from '@plexswap/sdk-core';
import sum from 'lodash/sum.js';
import chunk from 'lodash/chunk.js';
import { ChainId } from '@plexswap/chains';
import { FeeAmount } from '@plexswap/sdk-extended';
import { z } from 'zod';
import { getContract } from 'viem';

// Ananke/smartRouter.ts
var smartRouter_exports = {};
__export(smartRouter_exports, {
  APISchema: () => schema_exports,
  PlexMulticallProvider: () => PlexMulticallProvider,
  Transformer: () => transformer_exports,
  buildBaseRoute: () => buildBaseRoute,
  corePoolSubgraphSelection: () => corePoolTvlSelector,
  corePoolTvlSelector: () => corePoolTvlSelector,
  createCommonTokenPriceProvider: () => createCommonTokenPriceProvider,
  createCorePoolsProviderByCommonTokenPrices: () => createCorePoolsProviderByCommonTokenPrices,
  createGetCoreCandidatePools: () => createGetCoreCandidatePools,
  createGetExtendedCandidatePools: () => createGetExtendedCandidatePools,
  createGetExtendedCandidatePoolsWithFallbacks: () => createGetExtendedCandidatePools,
  createHybridPoolProvider: () => createHybridPoolProvider,
  createOffChainQuoteProvider: () => createOffChainQuoteProvider,
  createPoolProvider: () => createPoolProvider,
  createPoolQuoteGetter: () => createPoolQuoteGetter,
  createQuoteProvider: () => createQuoteProvider,
  createStaticPoolProvider: () => createStaticPoolProvider,
  encodeMixedRouteToPath: () => encodeMixedRouteToPath,
  extendedPoolSubgraphSelection: () => extendedPoolTvlSelector,
  extendedPoolTvlSelector: () => extendedPoolTvlSelector,
  extendedPoolsOnChainProviderFactory: () => extendedPoolsOnChainProviderFactory,
  getAllExtendedPoolsFromSubgraph: () => getAllExtendedPoolsFromSubgraph,
  getBestTrade: () => getBestTrade,
  getCandidatePools: () => getCandidatePools,
  getCheckAgainstBaseTokens: () => getCheckAgainstBaseTokens,
  getCommonTokenPrices: () => getCommonTokenPrices,
  getCommonTokenPricesByLlma: () => getCommonTokenPricesByLlma,
  getCommonTokenPricesBySubgraph: () => getCommonTokenPricesBySubgraph,
  getCommonTokenPricesByWalletApi: () => getCommonTokenPricesByWalletApi,
  getCoreCandidatePools: () => getCoreCandidatePools,
  getCorePoolSubgraph: () => getCorePoolSubgraph,
  getCorePoolsOnChain: () => getCorePoolsOnChain,
  getCorePoolsWithTvlByCommonTokenPrices: () => getCorePoolsWithTvlByCommonTokenPrices,
  getExecutionPrice: () => getExecutionPrice,
  getExtendedCandidatePools: () => getExtendedCandidatePools,
  getExtendedPoolSubgraph: () => getExtendedPoolSubgraph,
  getExtendedPoolsWithTvlFromOnChain: () => getExtendedPoolsWithTvlFromOnChain,
  getExtendedPoolsWithTvlFromOnChainFallback: () => getExtendedPoolsWithTvlFromOnChainFallback,
  getExtendedPoolsWithTvlFromOnChainStaticFallback: () => getExtendedPoolsWithTvlFromOnChainStaticFallback,
  getExtendedPoolsWithoutTicksOnChain: () => getExtendedPoolsWithoutTicksOnChain,
  getMidPrice: () => getMidPrice,
  getOutputOfPools: () => getOutputOfPools,
  getPairCombinations: () => getPairCombinations,
  getPoolAddress: () => getPoolAddress,
  getPriceImpact: () => getPriceImpact,
  getStableCandidatePools: () => getStableCandidatePools,
  getStablePoolsOnChain: () => getStablePoolsOnChain,
  getTokenUsdPricesBySubgraph: () => getTokenUsdPricesBySubgraph,
  involvesCurrency: () => involvesCurrency,
  isCorePool: () => isCorePool,
  isExtendedPool: () => isExtendedPool,
  isStablePool: () => isStablePool,
  log: () => log,
  logger: () => logger,
  maximumAmountIn: () => maximumAmountIn,
  metric: () => metric,
  minimumAmountOut: () => minimumAmountOut,
  partitionMixedRouteByProtocol: () => partitionMixedRouteByProtocol
});
function getTokenPriceByNumber(baseCurrency, quoteCurrency, price) {
  const quoteAmount = tryParseAmount_default(String(price), baseCurrency);
  const baseAmount = tryParseAmount_default("1", quoteCurrency);
  if (!baseAmount || !quoteAmount) {
    return void 0;
  }
  return new Price({ baseAmount, quoteAmount });
}
async function createGasModel({
  gasPriceWei,
  poolProvider,
  quoteCurrency,
  blockNumber,
  quoteCurrencyUsdPrice,
  nativeCurrencyUsdPrice
}) {
  const { chainId } = quoteCurrency;
  const usdToken = getUsdGasToken(chainId);
  if (!usdToken) {
    throw new Error(`No valid usd token found on chain ${chainId}`);
  }
  const nativeWrappedToken = getNativeWrappedToken(chainId);
  if (!nativeWrappedToken) {
    throw new Error(`Unsupported chain ${chainId}. Native wrapped token not found.`);
  }
  const gasPrice = BigInt(typeof gasPriceWei === "function" ? await gasPriceWei() : gasPriceWei);
  const [usdPool, nativePool] = await Promise.all([
    getHighestLiquidityUSDPool(poolProvider, chainId, blockNumber),
    getHighestLiquidityNativePool(poolProvider, quoteCurrency, blockNumber)
  ]);
  const priceInUsd = quoteCurrencyUsdPrice ? getTokenPriceByNumber(usdToken, quoteCurrency, quoteCurrencyUsdPrice) : void 0;
  const nativePriceInUsd = nativeCurrencyUsdPrice ? getTokenPriceByNumber(usdToken, nativeWrappedToken, nativeCurrencyUsdPrice) : void 0;
  const priceInNative = priceInUsd && nativePriceInUsd ? nativePriceInUsd.multiply(priceInUsd.invert()) : void 0;
  const estimateGasCost = ({ pools }, { initializedTickCrossedList }) => {
    const isQuoteNative = nativeWrappedToken.equals(quoteCurrency.wrapped);
    const totalInitializedTicksCrossed = BigInt(Math.max(1, sum(initializedTickCrossedList)));
    const poolTypeSet = /* @__PURE__ */ new Set();
    let baseGasUse = 0n;
    for (const pool of pools) {
      const { type } = pool;
      if (isCorePool(pool)) {
        if (!poolTypeSet.has(type)) {
          baseGasUse += BASE_SWAP_COST_CORE;
          poolTypeSet.add(type);
          continue;
        }
        baseGasUse += COST_PER_EXTRA_HOP_CORE;
        continue;
      }
      if (isExtendedPool(pool)) {
        if (!poolTypeSet.has(type)) {
          baseGasUse += BASE_SWAP_COST_EXTENDED(chainId);
          poolTypeSet.add(type);
        }
        baseGasUse += COST_PER_HOP_EXTENDED(chainId);
        continue;
      }
      if (isStablePool(pool)) {
        if (!poolTypeSet.has(type)) {
          baseGasUse += BASE_SWAP_COST_STABLE_SWAP;
          poolTypeSet.add(type);
          continue;
        }
        baseGasUse += COST_PER_EXTRA_HOP_STABLE_SWAP;
        continue;
      }
    }
    const tickGasUse = COST_PER_INIT_TICK(chainId) * totalInitializedTicksCrossed;
    const uninitializedTickGasUse = COST_PER_UNINIT_TICK * 0n;
    baseGasUse = baseGasUse + tickGasUse + uninitializedTickGasUse;
    const baseGasCostWei = gasPrice * baseGasUse;
    const totalGasCostNativeCurrency = CurrencyAmount.fromRawAmount(nativeWrappedToken, baseGasCostWei);
    let gasCostInToken = CurrencyAmount.fromRawAmount(quoteCurrency.wrapped, 0);
    let gasCostInUSD = CurrencyAmount.fromRawAmount(usdToken, 0);
    try {
      if (isQuoteNative) {
        gasCostInToken = totalGasCostNativeCurrency;
      }
      if (!isQuoteNative) {
        const price = priceInNative || nativePool && getTokenPrice(nativePool, nativeWrappedToken, quoteCurrency.wrapped);
        if (price) {
          gasCostInToken = price.quote(totalGasCostNativeCurrency);
        }
      }
      const nativeTokenUsdPrice = nativePriceInUsd || usdPool && getTokenPrice(usdPool, nativeWrappedToken, usdToken);
      if (nativeTokenUsdPrice) {
        gasCostInUSD = nativeTokenUsdPrice.quote(totalGasCostNativeCurrency);
      }
    } catch (e) {
    }
    return {
      gasEstimate: baseGasUse,
      gasCostInToken,
      gasCostInUSD
    };
  };
  return {
    estimateGasCost
  };
}
async function getHighestLiquidityNativePool(poolProvider, currency, blockNumber) {
  const nativeWrappedToken = getNativeWrappedToken(currency.chainId);
  if (!nativeWrappedToken || currency.wrapped.equals(nativeWrappedToken)) {
    return null;
  }
  const pools = await poolProvider.getCandidatePools({
    blockNumber,
    pairs: [[nativeWrappedToken, currency]],
    currencyA: nativeWrappedToken,
    currencyB: currency
  });
  return pools[0] ?? null;
}
async function getHighestLiquidityUSDPool(poolProvider, chainId, blockNumber) {
  const usdToken = getUsdGasToken(chainId);
  const nativeWrappedToken = getNativeWrappedToken(chainId);
  if (!usdToken || !nativeWrappedToken) {
    return null;
  }
  const pools = await poolProvider.getCandidatePools({
    blockNumber,
    pairs: [[nativeWrappedToken, usdToken]],
    currencyA: nativeWrappedToken,
    currencyB: usdToken
  });
  return pools[0] ?? null;
}
async function getRoutesWithValidQuote({
  amount,
  baseRoutes,
  distributionPercent,
  quoteProvider,
  tradeType,
  blockNumber,
  gasModel,
  quoterOptimization = true,
  signal
}) {
  const [percents, amounts] = getAmountDistribution(amount, distributionPercent);
  const routesWithoutQuote = amounts.reduce(
    (acc, curAmount, i) => [
      ...acc,
      ...baseRoutes.map((r) => ({
        ...r,
        amount: curAmount,
        percent: percents[i]
      }))
    ],
    []
  );
  const getRoutesWithQuote = tradeType === TradeType.EXACT_INPUT ? quoteProvider.getRouteWithQuotesExactIn : quoteProvider.getRouteWithQuotesExactOut;
  if (!quoterOptimization) {
    return getRoutesWithQuote(routesWithoutQuote, { blockNumber, gasModel, signal });
  }
  const requestCallback = typeof window === "undefined" ? setTimeout : window.requestIdleCallback || window.setTimeout;
  logger.metric("Get quotes", "from", routesWithoutQuote.length, "routes", routesWithoutQuote);
  const getQuotes = (routes) => new Promise((resolve, reject) => {
    requestCallback(async () => {
      try {
        const result2 = await getRoutesWithQuote(routes, { blockNumber, gasModel, signal });
        resolve(result2);
      } catch (e) {
        reject(e);
      }
    });
  });
  const chunks = chunk(routesWithoutQuote, 10);
  const result = await Promise.all(chunks.map(getQuotes));
  const quotes = result.reduce((acc, cur) => [...acc, ...cur], []);
  logger.metric("Get quotes", "success, got", quotes.length, "quoted routes", quotes);
  return quotes;
}

// Ananke/getBestTrade.ts
async function getBestTrade(amount, currency, tradeType, config) {
  const { blockNumber: blockNumberFromConfig } = config;
  const blockNumber = typeof blockNumberFromConfig === "function" ? await blockNumberFromConfig() : blockNumberFromConfig;
  const bestRoutes = await getBestRoutes(amount, currency, tradeType, {
    ...config,
    blockNumber
  });
  if (!bestRoutes || bestRoutes.outputAmount.equalTo(ZERO)) {
    throw new Error("Cannot find a valid swap route");
  }
  const { routes, gasEstimateInUSD, gasEstimate, inputAmount, outputAmount } = bestRoutes;
  return {
    tradeType,
    routes,
    gasEstimate,
    gasEstimateInUSD,
    inputAmount,
    outputAmount,
    blockNumber
  };
}
async function getBestRoutes(amount, currency, tradeType, routeConfig) {
  const { chainId } = currency;
  const {
    maxHops = 3,
    maxSplits = 4,
    distributionPercent = 5,
    poolProvider,
    quoteProvider,
    blockNumber,
    gasPriceWei,
    allowedPoolTypes,
    quoterOptimization,
    quoteCurrencyUsdPrice,
    nativeCurrencyUsdPrice,
    signal
  } = {
    ...routeConfig,
    ...ROUTE_CONFIG_BY_CHAIN[chainId] || {}
  };
  const isExactIn = tradeType === TradeType.EXACT_INPUT;
  const inputCurrency = isExactIn ? amount.currency : currency;
  const outputCurrency = isExactIn ? currency : amount.currency;
  const candidatePools = await poolProvider?.getCandidatePools({
    currencyA: amount.currency,
    currencyB: currency,
    blockNumber,
    protocols: allowedPoolTypes,
    signal
  });
  let baseRoutes = computeAllRoutes(inputCurrency, outputCurrency, candidatePools, maxHops);
  if (tradeType === TradeType.EXACT_OUTPUT) {
    baseRoutes = baseRoutes.filter(({ type }) => type !== 3 /* MIXED */);
  }
  const gasModel = await createGasModel({
    gasPriceWei,
    poolProvider,
    quoteCurrency: currency,
    blockNumber,
    quoteCurrencyUsdPrice,
    nativeCurrencyUsdPrice
  });
  const routesWithValidQuote = await getRoutesWithValidQuote({
    amount,
    baseRoutes,
    distributionPercent,
    quoteProvider,
    tradeType,
    blockNumber,
    gasModel,
    quoterOptimization,
    signal
  });
  return getBestRouteCombinationByQuotes(amount, currency, routesWithValidQuote, tradeType, { maxSplits });
}

// Ananke/schema.ts
var schema_exports = {};
__export(schema_exports, {
  zPools: () => zPools,
  zRouterGetParams: () => zRouterGetParams,
  zRouterPostParams: () => zRouterPostParams
});
var zChainId = z.nativeEnum(ChainId);
var zFee = z.nativeEnum(FeeAmount);
var zTradeType = z.nativeEnum(TradeType);
var zPoolType = z.nativeEnum(PoolType);
var zPoolTypes = z.array(zPoolType);
var zAddress = z.custom((val) => /^0x[a-fA-F0-9]{40}$/.test(val));
var zBigNumber = z.string().regex(/^[0-9]+$/);
var zCurrency = z.object({
  address: zAddress,
  decimals: z.number(),
  symbol: z.string()
}).required();
var zCurrencyAmount = z.object({
  currency: zCurrency.required(),
  value: zBigNumber
}).required();
var zCorePool = z.object({
  type: z.literal(0 /* CORE */),
  reserve0: zCurrencyAmount,
  reserve1: zCurrencyAmount
}).required();
var zExtendedPool = z.object({
  type: z.literal(1 /* EXTENDED */),
  token0: zCurrency,
  token1: zCurrency,
  fee: zFee,
  liquidity: zBigNumber,
  sqrtRatioX96: zBigNumber,
  tick: z.number(),
  address: zAddress,
  token0ProtocolFee: z.string(),
  token1ProtocolFee: z.string()
}).required();
var zStablePool = z.object({
  type: z.literal(2 /* STABLE */),
  balances: z.array(zCurrencyAmount),
  amplifier: zBigNumber,
  fee: z.string()
}).required();
var zPools = z.array(z.union([zCorePool, zExtendedPool, zStablePool]));
var zRouterGetParams = z.object({
  chainId: zChainId,
  tradeType: zTradeType,
  amount: zCurrencyAmount,
  currency: zCurrency,
  gasPriceWei: zBigNumber.optional(),
  maxHops: z.number().optional(),
  maxSplits: z.number().optional(),
  blockNumber: zBigNumber.optional(),
  poolTypes: zPoolTypes.optional()
}).required({
  chainId: true,
  tradeType: true,
  amount: true,
  currency: true,
  candidatePools: true
});
var zRouterPostParams = z.object({
  chainId: zChainId,
  tradeType: zTradeType,
  amount: zCurrencyAmount,
  currency: zCurrency,
  candidatePools: zPools,
  gasPriceWei: zBigNumber.optional(),
  maxHops: z.number().optional(),
  maxSplits: z.number().optional(),
  blockNumber: zBigNumber.optional(),
  poolTypes: zPoolTypes.optional(),
  onChainQuoterGasLimit: zBigNumber.optional(),
  nativeCurrencyUsdPrice: z.number().optional(),
  quoteCurrencyUsdPrice: z.number().optional()
}).required({
  chainId: true,
  tradeType: true,
  amount: true,
  currency: true,
  candidatePools: true
});

// config/abis/FeeOnTransferDetector.ts
var feeOnTransferDetectorAbi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "_factoryV2",
        type: "address"
      }
    ],
    stateMutability: "nonpayable",
    type: "constructor"
  },
  {
    inputs: [],
    name: "PairLookupFailed",
    type: "error"
  },
  {
    inputs: [],
    name: "SameToken",
    type: "error"
  },
  {
    inputs: [
      {
        internalType: "address[]",
        name: "tokens",
        type: "address[]"
      },
      {
        internalType: "address",
        name: "baseToken",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "amountToBorrow",
        type: "uint256"
      }
    ],
    name: "batchValidate",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "buyFeeBps",
            type: "uint256"
          },
          {
            internalType: "uint256",
            name: "sellFeeBps",
            type: "uint256"
          }
        ],
        internalType: "struct TokenFees[]",
        name: "fotResults",
        type: "tuple[]"
      }
    ],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "amount0",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      },
      {
        internalType: "bytes",
        name: "data",
        type: "bytes"
      }
    ],
    name: "plexswapCall",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address"
      },
      {
        internalType: "address",
        name: "baseToken",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "amountToBorrow",
        type: "uint256"
      }
    ],
    name: "validate",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "buyFeeBps",
            type: "uint256"
          },
          {
            internalType: "uint256",
            name: "sellFeeBps",
            type: "uint256"
          }
        ],
        internalType: "struct TokenFees",
        name: "fotResult",
        type: "tuple"
      }
    ],
    stateMutability: "nonpayable",
    type: "function"
  }
];

// Ananke/fot.ts
var getFeeOnTransferDetectorContract = (publicClient) => {
  if (publicClient.chain && publicClient.chain.id in feeOnTransferDetectorAddresses) {
    return getContract({
      abi: feeOnTransferDetectorAbi,
      address: feeOnTransferDetectorAddresses[publicClient.chain.id],
      client: publicClient
    });
  }
  return null;
};
var AMOUNT = 100000n;
async function fetchTokenFeeOnTransfer(publicClient, tokenAddress) {
  if (!publicClient.chain) {
    throw new Error("Chain not found");
  }
  const contract = getFeeOnTransferDetectorContract(publicClient);
  const baseToken = WNATIVE[publicClient.chain.id];
  if (!contract) {
    throw new Error("Fee on transfer detector contract not found");
  }
  if (!baseToken) {
    throw new Error("Base token not found");
  }
  if (tokenAddress.toLowerCase() === baseToken.address.toLowerCase()) {
    throw new Error("Token is base token");
  }
  return contract.simulate.validate([tokenAddress, baseToken.address, AMOUNT]);
}
async function fetchTokenFeeOnTransferBatch(publicClient, tokens) {
  if (!publicClient.chain) {
    throw new Error("Chain not found");
  }
  const contract = getFeeOnTransferDetectorContract(publicClient);
  if (!contract) {
    throw new Error("Fee on transfer detector contract not found");
  }
  const baseToken = WNATIVE[publicClient.chain.id];
  if (!baseToken) {
    throw new Error("Base token not found");
  }
  const tokensWithoutBaseToken = tokens.filter(
    (token) => token.address.toLowerCase() !== baseToken.address.toLowerCase()
  );
  return publicClient.multicall({
    allowFailure: true,
    contracts: tokensWithoutBaseToken.map(
      (token) => ({
        address: contract.address,
        abi: feeOnTransferDetectorAbi,
        functionName: "validate",
        args: [token.address, baseToken.address, AMOUNT]
      })
    )
  });
}

export { fetchTokenFeeOnTransfer, fetchTokenFeeOnTransferBatch, smartRouter_exports };
