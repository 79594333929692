import { ChainId } from '@plexswap/chains';

// config/constants/contracts.ts
var PERMIT2_ADDRESSES = {
  [ChainId.PLEXCHAIN]: "0x0",
  [ChainId.BSC]: "0x31c2F6fcFf4F8759b3Bd5Bf0e1084A055615c768",
  [ChainId.BSC_TESTNET]: "0x31c2F6fcFf4F8759b3Bd5Bf0e1084A055615c768"
};

export { PERMIT2_ADDRESSES };
