import { ChainId } from '@plexswap/chains';
import { bscTokens, bscTestnetTokens, plexchainTokens } from '@plexswap/tokens';

var __defProp = Object.defineProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var BASES_TO_CHECK_TRADES_AGAINST = {
  [ChainId.BSC]: [
    bscTokens.wbnb,
    bscTokens.waya,
    bscTokens.busd,
    bscTokens.usdt,
    bscTokens.btcb,
    bscTokens.eth,
    bscTokens.usdc
  ],
  [ChainId.BSC_TESTNET]: [bscTestnetTokens.wbnb, bscTestnetTokens.waya, bscTestnetTokens.busd],
  [ChainId.PLEXCHAIN]: [plexchainTokens.wplex, plexchainTokens.waya, plexchainTokens.usdp]
};
var ADDITIONAL_BASES = {
  [ChainId.BSC]: {}
};
var CUSTOM_BASES = {
  [ChainId.BSC]: {}
};
var ROUTER_ADDRESS = {
  [ChainId.BSC]: "0x205ce30FB7Ef4173f05979421a73Def4f6983C47",
  [ChainId.BSC_TESTNET]: "0x995214A87ADAdbe30e7132BC269AF996004BA48D",
  [ChainId.PLEXCHAIN]: "0x7E961C57bFEE77716d1E46D2e942347CfdEaD8DF"
};
var SMART_ROUTER_ADDRESSES = {
  [ChainId.PLEXCHAIN]: "0x0",
  [ChainId.BSC]: "0xaA8eDF290123E0C94C6F5B74EE771c394337B803",
  [ChainId.BSC_TESTNET]: "0xcfEbCdB909c74C5eD7342Db656f620E86d7D536f"
};
var EVOLUS_ROUTER_ADDRESSES = {
  [ChainId.PLEXCHAIN]: "0x0",
  [ChainId.BSC]: "0x1A0A18AC4BECDDbd6389559687d1A73d8927E416",
  [ChainId.BSC_TESTNET]: "0xd77C2afeBf3dC665af07588BF798bd938968c72E"
};
var STABLE_SWAP_INFO_ADDRESS = {
  [ChainId.PLEXCHAIN]: "0x0",
  [ChainId.BSC]: "0x37dEa8F642b8d8bdB1e3a8697Ab35DE07C37EeC6",
  [ChainId.BSC_TESTNET]: "0x7f802f3e2AF210763E940AE5557aa988daeC3e6C"
};
var MIXED_ROUTE_QUOTER_ADDRESSES = {
  [ChainId.PLEXCHAIN]: "0x0",
  [ChainId.BSC]: "0x6916265bEbc706B789C6A234f736355d0cf5C6B3",
  [ChainId.BSC_TESTNET]: "0x2FA60cf7573a4e2314258Cf39bA36F1666092EE2"
};
var EXTENDED_QUOTER_ADDRESSES = {
  [ChainId.PLEXCHAIN]: "0x0",
  [ChainId.BSC]: "0xD581E934277309d3A5B5473CC2E01593163b1403",
  [ChainId.BSC_TESTNET]: "0x108a2e26eC676fe40B96A17051D78e0d9d3C8297"
};
var feeOnTransferDetectorAddresses = {
  [ChainId.PLEXCHAIN]: "0x0",
  [ChainId.BSC]: "0x003BD52f589F23346E03fA431209C29cD599d693",
  [ChainId.BSC_TESTNET]: "0xE83BD854c1fBf54424b4d914163BF855aB1131Aa"
};

export { ADDITIONAL_BASES, BASES_TO_CHECK_TRADES_AGAINST, CUSTOM_BASES, EVOLUS_ROUTER_ADDRESSES, EXTENDED_QUOTER_ADDRESSES, MIXED_ROUTE_QUOTER_ADDRESSES, ROUTER_ADDRESS, SMART_ROUTER_ADDRESSES, STABLE_SWAP_INFO_ADDRESS, __export, feeOnTransferDetectorAddresses };
